@import '~materialize-css/dist/css/materialize.min.css';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

html {
  font-family: Georgia;
  background-color: #e0f2f1;
  color: #333;
  font-size: 16px;
}

main {
  padding-left: 222px;
  background: url(font.png) repeat;
  margin-top: -64px;
}

h3 {
  font-weight: 200;
  margin-bottom: 40px;
  margin-top: 0;
  padding: 30px 40px;
  text-transform: uppercase;
}

.full-height {
  height: 100vh;
}

.container {
  padding: 0 0.75rem;
}

#Education .container {
  padding: 0px;
}

@media only screen and (min-width: 601px) {
  nav,
  nav .nav-wrapper i,
  nav a.sidenav-trigger,
  nav a.sidenav-trigger i,
  .navbar-fixed {
    height: 56px !important;
    line-height: 56px !important;
  }

  #Projects .col.s12.m10.link.text {
    text-align: left;
  }

  #About .container,
  #Intro .container {
    padding-bottom: 30px;
  }

  #Intro .container h5 {
    font-size: 32px;
    line-height: 200%;
    margin: 30px 0 0;
  }
}

@media only screen and (min-width: 992px) {
  .test-link-mini {
    display: none;
  }

  .card .card-project .card-image {
    border: 1px solid #ddd;
    margin-top: 24px;
  }
}

.row:after {
  content: '';
  display: table;
  clear: both;
}

.card {
  margin-bottom: 60px;
}

.card .role {
  font-size: 1.25rem;
  position: absolute;
  right: 38px;
  top: 35px;
}

.card li a {
  margin-right: 0;
  color: #333;
  border-bottom: 2px solid #ffab40;
}

.card li a:hover {
  color: #333;
  font-weight: 500;
}

.card .card-content {
  padding-bottom: 30px;
}

.card .card-content p {
  padding: 5px 0 0;
}

.card h5 {
  font-size: 1.5rem;
  margin-bottom: -0.5rem;
  margin-top: 2rem;
}

.card h6 {
  font-weight: 300;
  letter-spacing: 1px;
  margin-top: 2rem;
  text-transform: uppercase;
}

#Projects .card-action {
  background-color: rgba(0, 0, 0, 0.02);
  border: none;
}

#Projects .col.s12.m10.link.text.text-newline {
  word-break: break-all;
}

#Projects .card .col.s12.m2 a img {
  max-height: 60px;
}

.card-action span {
  font-size: 85%;
  color: #666;
}

.card ul {
  margin-bottom: 0;
}

.card ul li {
  line-height: 200%;
  list-style-type: disc;
  margin-left: 24px;
}

.card ul li li {
  color: #666;
  font-size: 13px;
}

.card .card-title {
  font-size: 24px;
}

.card .card-content .card-title {
  line-height: 2.5rem;
}

.card i.right {
  margin-left: 10px;
}

a.hoverline {
  border-bottom: 3px solid transparent !important;
  padding: 3px 3px 0 3px;
  transition: 0.25s all;
  -moz-transition: 0.25s all;
  -webkit-transition: 0.25s all;
}

a.hoverline:hover {
  border-bottom: 3px solid #009688 !important;
}

#skills .card.large {
  height: 360px;
}

#skills h4 {
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

#skills .col img.responsive-img {
  margin: 0 auto;
  max-height: 100px;
  padding-bottom: 10px;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.card.large .card-content p {
  color: inherit;
  margin: 0;
  padding: 30px 0;
}

#Projects .card-content .row {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.card .card-project .card-image {
  border: 1px solid #ddd;
  margin-top: 24px;
}

.card-action a.btn-floating {
  margin-bottom: 0;
}

@media only screen and (max-width: 992px) {
  .test-link-screen {
    display: none;
  }

  main {
    padding-left: 0;
    margin-top: 0px;
  }

  h3 {
    padding: 20px 30px;
    margin-bottom: 40px;
    font-size: 30px;
  }

  .card .role {
    position: relative;
    right: initial;
    top: initial;
  }

  section#Intro {
    background-position-x: 62%;
  }
}

@media (max-width: 1200px) {
  .card .card-title {
    font-size: 18px;
  }
}

.sidenav {
  width: 222px;
  background-color: #e0f2f1;
}

.sidenav li > a.intro {
  height: 146px;
  border-bottom: 1px solid #ddd;
  background-color: #e0f2f1;
  /* margin-top: 0px; */
}

.sidenav li > a {
  padding: 0px 24px;
  background-color: #e0f2f1;
}

.sidenav li > a.intro > h4 {
  margin-top: 0px;
  margin-bottom: 0px;
  /* padding-top: 12px; */
  padding: 12px 12px 0px 12px;
  /* color: #1E88E5; */
  color: #00acc1;
  font-style: italic;
  font-weight: 200;
}

.sidenav li > a.intro > h5 {
  font-size: 16px;
  padding: 0px 0px 0px 12px;
  font-style: italic;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 1.25;
}

.sidenav li > a.intro > span {
  font-style: italic;
  display: block;

  padding: 0px 0px 0px 12px;
  line-height: 1.25;
}

.sidenav li > a.intro-mobile > span {
  font-style: italic;
  display: block;
  font-size: 14px;
  padding: 0px 0px 0px 20px;
  line-height: 1;
}

.strong {
  font-weight: 600;
}

.sidenav li > a.side-bg-color.active {
  background-color: #b2dfdb;
}

.sidenav li > a.intro-mobile {
  color: #00acc1;
  font-style: italic;
  font-size: 22px;
  padding-bottom: 96px;
  border-bottom: 2px solid #ddd;
}

nav .brand-logo {
  font-size: 26px;
}

#Contact a:not(.btn-floating) {
  left: 20px;
  position: relative;
}

@media (max-width: 600px) {
  #Contact a:not(.btn-floating) {
    text-align: center;
    display: block;
    left: initial;
    bottom: initial;
  }

  #Contact a.btn-floating.btn-large {
    display: block;
    text-align: center;
    margin: 0 auto;
  }

  #Projects table.striped > tbody > tr > td {
    font-size: 14px;
  }

  #Projects .col.s12.m2.link,
  #Projects .col.s12.m10.link {
    font-size: 12px;
  }

  #Projects .card-title {
    text-align: center;
  }

  section#Intro {
    background-position-x: 55%;
  }
}

@media (max-width: 400px) {
  #Projects .col.s12.m10.link.calcnew {
    font-size: 11px;
    padding: 0rem;
  }
}

.border-bottom {
  border-bottom: 1px solid #ddd;
  padding-bottom: 12px;
}

#Projects .col.s12.m2.link,
#Projects .col.s12.m10.link {
  text-align: center;
}

#Projects .col.s12.m10.link.text {
  margin-top: 12px;
}

#Projects .linksName {
  margin-bottom: 24px;
}

#Projects table.striped > tbody > tr > td {
  padding: 5px 5px;
}

.spravca {
  border-bottom: 1px solid #ddd;
}

.reference {
  display: none;
}

.reference ~ .story {
  display: none;
}

.reference + label {
  font-size: 16px;
  padding: 0;
  color: green;
  cursor: pointer;
  display: inline-block;
}

.reference:checked ~ .story {
  display: block;
  padding: 10px;
}

blockquote {
  border-left: 5px solid #616161;
  color: #616161;
  font-size: 125%;
  font-weight: 400;
  margin: 20px 0;
  padding-left: 1.5rem;
}

#Skills img.responsive-img {
  margin: 0 auto;
  /* max-height: 100px; */
  height: 100px;
  padding-bottom: 10px;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

#Skills h4 {
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

#WriteMe .yellow-input {
  border-bottom: 1px solid #fff !important;
  box-shadow: 0 1px 0 0 #fff !important;
}

#WriteMe .yellow-input + label {
  color: #ffffff !important;
}

#WriteMe .yellow-input:focus + label {
  color: #ffeb3b !important;
}

#WriteMe .yellow-input:focus {
  border-bottom: 1px solid #ffeb3b !important;
  box-shadow: 0 1px 0 0 #ffeb3b !important;
}

#Contact,
#Resume {
  margin-bottom: 36px;
}

.page-title {
  background: #00acc1 !important;
}

div.waves-effect .waves-ripple {
  top: 50px;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

#Intro {
  background: url(cssImages/siniakoualex1600X992.jpg) no-repeat top center/cover;
  position: relative;
  height: 100vh;
}

#Intro .container {
  color: white;
  font-weight: 200;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

#Intro .container .nowrap {
  white-space: nowrap;
}
